import { sign as signJwt, verify as verifyJwt } from 'jsonwebtoken';

export const JWT_ISSUER_LEVITY = 'Levity:2';

export const sign = (payload: string | Record<string, unknown>, privateKey: string) =>
  signJwt(payload, privateKey, {
    algorithm: 'HS256',
    issuer: JWT_ISSUER_LEVITY,
    expiresIn: '24h',
  });

/**
 * DO NOT USE TO SIGN USER TOKENS.
 * These tokens are only meant to be used for machine to machine communication.
 * @param payload
 * @param privateKey
 * @returns
 */
export const signMachineToMachineToken = (payload: string | Record<string, unknown>, privateKey: string) =>
  signJwt(payload, privateKey, {
    algorithm: 'HS256',
    issuer: JWT_ISSUER_LEVITY,
  });

export const verify = (token: string, privateKey: string) => {
  try {
    verifyJwt(token, privateKey, {
      algorithms: ['HS256'],
      issuer: JWT_ISSUER_LEVITY,
    });

    return true;
  } catch (error) {
    return false;
  }
};

export const decodeJwt = <T>(token: string, privateKey: string): T | Error | null => {
  try {
    const payload = verifyJwt(token, privateKey, {
      algorithms: ['HS256'],
      issuer: JWT_ISSUER_LEVITY,
    }) as T;

    return payload;
  } catch (error: unknown) {
    if (error instanceof Error && error.message === 'jwt expired') {
      throw new Error('jwt expired');
    }
    return null;
  }
};
