import type { IBaseGetUserResponse } from '@frontegg/rest-api';
import type { LevityUser, LevityUserWorkspaceMapping } from '@prisma/client';
import { signIn } from 'next-auth/react';
import type { ParsedUrlQuery } from 'querystring';

import { env } from '@/lib/env';
import { decodeJwt, JWT_ISSUER_LEVITY, sign } from '@/utils/jwt';
import { isTimestampExpired } from '@/utils/time';
import { URLs } from '@/utils/urls';

import { EMAIL_PROVIDERS } from './constants';
import { emailSchema } from './schemas/api/inputs';
import type { InviteTokenDecodedPayload, ValideInviteTokenParams, WorkspaceInvite } from './types';
import { WorkspaceInviteError } from './types';

export const signInWithGoogle = () => {
  signIn('google');
};

export const signInWithEmail = async (email: string, callbackUrl: string) => {
  const validatedEmail = emailSchema.safeParse(email);

  if (!validatedEmail.success) {
    throw new Error('Invalid email format');
  }

  return signIn('email', {
    email: validatedEmail.data,
    callbackUrl,
    redirect: false,
  });
};

export const getEmailProviderInfo = (email: string) => {
  const domain = email.split('@')[1];
  if (!domain) return null;

  const providerInfo = EMAIL_PROVIDERS[domain];
  return providerInfo || null;
};

export const redirectInfo = (accessToken: string, query: ParsedUrlQuery) => {
  let redirectUrl = '/';

  if (query.redirect_uri) {
    redirectUrl = `${query.redirect_uri as string}?code=${accessToken}&response_type=${query.response_type}&state=${
      query.state
    }`;
  }
  return {
    redirect: { destination: redirectUrl },
  };
};

export const mapFronteggUserToLevityUser = (fronteggUser: IBaseGetUserResponse): LevityUser => ({
  id: fronteggUser.id,
  email: fronteggUser.email,
  createdAt: fronteggUser.createdAt ? new Date(fronteggUser.createdAt) : new Date(),
  updatedAt: new Date(),
  levityWorkspaceId: fronteggUser.tenantId,
});

// TODO: We should rename WORKFLOWS_API_CALL_VALIDATION_STRING to JWT_PRIVATE_KEY
export const createInviteToken = (workspaceId: string, email: string) =>
  sign(
    {
      workspaceId,
      email,
    },
    env.WORKFLOWS_API_CALL_VALIDATION_STRING,
  );

export const createInviteUrl = (workspaceId: string, email: string) => {
  const inviteToken = createInviteToken(workspaceId, email);
  const url = `${env.NEXT_PUBLIC_NEXTAUTH_URL}${URLs.WorkspaceInvitation().pathname}?inviteToken=${inviteToken}`;

  return url;
};

export const validateInviteToken = (params: ValideInviteTokenParams): WorkspaceInvite => {
  const { inviteToken, validWorkspaceIds, validWorkspaceMappings, currentUserEmail, currentUserId } = params;
  if (!inviteToken) return makeInvalidInvite(WorkspaceInviteError.INVALID_TOKEN);

  let decodedPayload: InviteTokenDecodedPayload;
  try {
    decodedPayload = decodeJwt(inviteToken, env.WORKFLOWS_API_CALL_VALIDATION_STRING) as InviteTokenDecodedPayload;
    if (!decodedPayload) throw new Error('Could not decode the invite token');
  } catch (error: unknown) {
    if (error instanceof Error && error.message === 'jwt expired') {
      return makeInvalidInvite(WorkspaceInviteError.EXPIRED_TOKEN);
    }
    return makeInvalidInvite(WorkspaceInviteError.INVALID_TOKEN);
  }

  const { email, workspaceId, exp, iss } = decodedPayload;

  if (isTimestampExpired(exp)) return makeInvalidInvite(WorkspaceInviteError.EXPIRED_TOKEN, email, workspaceId);

  if (!isValidIssuer(iss)) return makeInvalidInvite(WorkspaceInviteError.ISSUER_UNKNOWN, email, workspaceId);

  if (!isCurrentUser(email, currentUserEmail))
    return makeInvalidInvite(WorkspaceInviteError.UNAUTHORIZED, email, workspaceId);

  if (!isValidWorkspace(workspaceId, validWorkspaceIds))
    return makeInvalidInvite(WorkspaceInviteError.WORKSPACE_UNKNOWN, email, workspaceId);

  if (isExistingUserWorkspaceMapping(currentUserId, workspaceId, validWorkspaceMappings))
    return makeInvalidInvite(WorkspaceInviteError.INVITE_USED, email, workspaceId);

  return {
    email,
    userId: currentUserId,
    workspaceId,
    isValid: true,
    error: null,
  };
};

const makeInvalidInvite = (error: WorkspaceInviteError, email?: string, workspaceId?: string): WorkspaceInvite => {
  return {
    email: email || null,
    workspaceId: workspaceId || null,
    isValid: false,
    error,
  };
};

const isValidIssuer = (iss: string) => iss === JWT_ISSUER_LEVITY;

const isValidWorkspace = (workspaceId: string, validWorkspaceIds: string[]) => validWorkspaceIds.includes(workspaceId);

const isCurrentUser = (invitedUserEmail?: string, currentUserEmail?: string) => {
  return Boolean(invitedUserEmail) && Boolean(currentUserEmail) && invitedUserEmail === currentUserEmail;
};

const isExistingUserWorkspaceMapping = (
  userId: string | undefined,
  workspaceId: string,
  workspaceMappings: LevityUserWorkspaceMapping[],
) => {
  return workspaceMappings
    .map((mapping) => mapping.userId === userId && mapping.workspaceId === workspaceId)
    .some(Boolean);
};

export const getWorkspaceInviteErrorDetails = (error: WorkspaceInviteError) => {
  let title: string;
  let description: string;

  switch (error) {
    case WorkspaceInviteError.INVALID_TOKEN:
      title = "This invite link isn't valid";
      description = 'Please double-check the link or get a new one from the person who shared it with you.';
      break;
    case WorkspaceInviteError.INVITE_USED:
      title = 'This invite link was already used';
      description = "Please check with the person who shared it with you to see if there's a new link available.";
      break;
    case WorkspaceInviteError.EXPIRED_TOKEN:
      title = 'This invite link is no longer active';
      description = "Please check with the person who shared it with you to see if there's a new link available.";
      break;
    case WorkspaceInviteError.ISSUER_UNKNOWN:
      title = "We can't verify the source of this invite";
      description = "Please check with the person who shared it with you to see if there's a new link available.";
      break;
    case WorkspaceInviteError.WORKSPACE_UNKNOWN:
      title = "We can't find this workspace";
      description =
        'The workspace you are trying to join may have been deleted or changed. Verify the link and please check with the person who shared it with you.';
      break;
    case WorkspaceInviteError.USER_UNKNOWN:
      title = "We don't recognize your account";
      description = 'Please sign in with the account you were invited to join the workspace with.';
      break;
    case WorkspaceInviteError.UNAUTHORIZED:
      title = "You don't have permission to join this workspace";
      description = "Make sure you're logged in with the same email address the workspace invitation was sent to.";
      break;
    case WorkspaceInviteError.UNKNOWN:
    default:
      title = 'Something went wrong';
      description = "We're not sure what happened. Please try again later or contact support.";
      break;
  }

  return { title, description };
};
