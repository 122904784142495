import Image from 'next/image';
import React from 'react';

import { Button, ButtonVariant, Icons } from '@/ui-library';

import type { MagicLinkPanelProps } from './magic-link-panel.types';

export const MagicLinkPanel: React.FC<MagicLinkPanelProps> = (props) => {
  const { email, emailProvider } = props;

  const openNewWindow = (url: string) => window.open(url, '_blank');

  return (
    <div className='flex flex-col justify-center items-center'>
      <Icons.MagicWand />

      <div className='h-4' />
      <h1 className='text-2xl font-bold'>Magic link sent</h1>
      <div className='h-4' />

      <div className='flex flex-col items-center gap-1' data-testid='magic-link-signup-info'>
        <h2 className='text-NeutralDarkLight font-normal text-center'>
          We have sent an email to
          <span className='text-NeutralDarkLight font-semibold pl-1'>{email}</span> with a link
          <br />
          to get you signed in quickly.
        </h2>
      </div>

      <div className='h-8' />

      {emailProvider && (
        <Button
          text={`Go to ${emailProvider.name}`}
          icon={<Image src={emailProvider.imgSrc} width={16} height={16} alt={`${emailProvider.name} icon`} />}
          onClick={() => openNewWindow(emailProvider.url)}
          variant={ButtonVariant.Secondary}
          type='button'
        />
      )}
    </div>
  );
};
