import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect } from 'react';

import { Button, ButtonVariant, Form, useForm } from '@/ui-library';
import { URLs } from '@/utils/urls';

import { userEmail } from '../../levity/accounts/schemas/api/inputs';
import type { AuthPanelProps, EmailFormContentProps } from './auth-panel.types';

export const AuthPanel: React.FC<AuthPanelProps> = (props) => {
  const { copy, toggleAuthLink, emailAuth, googleAuth } = props;

  return (
    <div className='flex flex-col items-center px-12 mx-8 sm:px-[72px] pt-9 pb-16 bg-NeutralLightLightest rounded-2xl shadow-auth-panel'>
      <div className='h-2' />
      <h1 className='text-2xl font-bold'>{copy.heading}</h1>
      <div className='h-4' />
      <div className='flex items-center text-sm leading-4 gap-2'>
        <span className='text-NeutralDarkLight font-normal'>{copy.subheading}</span>
        <Link href={toggleAuthLink.href} className='text-HighlightDarkest font-bold'>
          {toggleAuthLink.text}
        </Link>
      </div>
      <div className='h-11' />
      <Button
        text='Google'
        icon={<Image src='/imgs/icons/google.svg' width={16} height={16} alt='Gmail icon' />}
        className='min-w-[200px] sm:min-w-[384px]'
        variant={ButtonVariant.Secondary}
        onClick={() => {
          googleAuth.onAuthenticate();
          googleAuth.postAuthenticateProcedure?.();
        }}
        type='button'
      />

      <div className=' h-9' />
      <span className='text-NeutralDarkLight font-normal text-sm leading-4'>{copy.or}</span>
      <div className='h-5' />

      <Form<{ email: string }>
        onSubmit={(params) => {
          emailAuth.onAuthenticate(params);
          emailAuth.postAuthenticateProcedure?.();
        }}
        schema={userEmail}
        id='form'>
        <EmailFormContent copy={copy} emailAuth={emailAuth} />
      </Form>

      <div className='h-2.5' />

      <div className='flex flex-col sm:flex-row items-center text-[11px] leading-4 gap-1'>
        <span className='text-NeutralDarkLight font-normal'>By signing up, I agree with the</span>
        <div className='flex items-center gap-1'>
          <Link target='_blank' href={URLs.Terms().pathname} className='text-HighlightDarkest font-bold'>
            Terms
          </Link>
          <span className='text-NeutralDarkLight font-normal'>and</span>
          <Link target='_blank' href={URLs.PrivacyPolicy().pathname} className='text-HighlightDarkest font-bold'>
            Privacy Policy
          </Link>
        </div>
      </div>
    </div>
  );
};

export interface EmailFormContentType {
  email: string;
}

// Created separate component for FormContent to get access to formState via useForm
const EmailFormContent: React.FC<EmailFormContentProps> = (props) => {
  const { copy, emailAuth } = props;
  const {
    watch,
    setError: setFormError,
    formState: { errors },
  } = useForm<EmailFormContentType>();
  const hasEmailValue = Boolean(watch('email'));
  useEffect(() => {
    setFormError('email', { message: copy.emailSupportText });
  }, [copy.emailSupportText]);
  return (
    <div className='flex flex-col items-center'>
      <div className='w-full'>
        <Form.Input
          name='email'
          label='Email'
          supportText={errors.email ? copy.emailSupportText : ''}
          placeholder='Enter your email'
        />
      </div>

      <div className='h-5' />
      <Button
        text={copy.emailButton}
        className='min-w-[200px] sm:min-w-[384px]'
        onClick={emailAuth.onClick}
        type='submit'
        isLoading={emailAuth.isLoading}
        disabled={emailAuth.isLoading || !hasEmailValue}
      />
    </div>
  );
};
