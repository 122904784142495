import type { LevityUserWorkspaceMapping } from '@prisma/client';

export interface EmailProviderInfo {
  name: string;
  url: string;
  imgSrc: string;
}

export interface InviteTokenInputPayload {
  workspaceId: string;
  email: string;
}

export type InviteTokenDecodedPayload = InviteTokenInputPayload & {
  iat: number;
  exp: number;
  iss: string;
};

export interface ValideInviteTokenParams {
  inviteToken: string | undefined;
  validWorkspaceIds: string[];
  validWorkspaceMappings: LevityUserWorkspaceMapping[];
  currentUserEmail?: string;
  currentUserId?: string;
}

export enum WorkspaceInviteError {
  INVALID_TOKEN = 'invalid token',
  EXPIRED_TOKEN = 'token expired',
  ISSUER_UNKNOWN = 'token issuer unknown',
  WORKSPACE_UNKNOWN = 'workspace unknown',
  INVITE_USED = 'invite used',
  USER_UNKNOWN = 'user unknown',
  UNAUTHORIZED = 'unauthorized',
  UNKNOWN = 'unknown',
}

export interface WorkspaceInvite {
  email?: string | null;
  userId?: string;
  workspaceId?: string | null;
  isValid: boolean;
  error: WorkspaceInviteError | null;
}
