import type { EmailProviderInfo } from './types';

export const EMAIL_PROVIDERS: { [key: string]: EmailProviderInfo } = {
  'gmail.com': {
    name: 'Gmail',
    url: 'https://mail.google.com/',
    imgSrc: '/imgs/icons/gmail.svg',
  },
  'yahoo.com': {
    name: 'Yahoo',
    url: 'https://mail.yahoo.com/',
    imgSrc: '/imgs/icons/yahoo.svg',
  },
  'outlook.com': {
    name: 'Outlook',
    url: 'https://outlook.live.com/',
    imgSrc: '/imgs/icons/outlook.svg',
  },
  'aol.com': {
    name: 'AOL',
    url: 'https://mail.aol.com/',
    imgSrc: '/imgs/icons/aol.svg',
  },
  'icloud.com': {
    name: 'iCloud',
    url: 'https://www.icloud.com/mail',
    imgSrc: '/imgs/icons/icloud-mail.svg',
  },
};
