import { useState } from 'react';

import { ToastType, useToast } from '@/ui-library';

import { getEmailProviderInfo, signInWithEmail } from './core';
import type { EmailProviderInfo } from './types';

export const useEmailAuth = () => {
  const { showToast } = useToast();

  const [email, setEmail] = useState('');
  const [emailProvider, setEmailProvider] = useState<EmailProviderInfo | null>(null);
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isMagicLinkSent, setIsMagicLinkSent] = useState(false);

  async function authenticate(email: string, callbackUrl: string, onSuccess?: () => void) {
    setIsLoading(true);
    const res = await signInWithEmail(email, callbackUrl);
    setIsLoading(false);

    if (res?.ok && !res.error) {
      setEmail(email);
      setEmailProvider(getEmailProviderInfo(email));
      setIsMagicLinkSent(true);
      showToast({
        type: ToastType.Success,
        title: 'Email sent successfully!',
      });

      onSuccess?.();
    } else {
      showToast({
        type: ToastType.Error,
        title: 'Email failed!',
        description: 'Check your input and try again.',
      });
      setEmail('');
      setEmailProvider(null);
      setError('Failed! Check your input and try again.');
    }
  }

  return {
    authenticate,
    email,
    emailProvider,
    error,
    isLoading,
    isMagicLinkSent,
    setError,
  };
};
